import React, { useState } from 'react'
import { createGlobalState } from 'react-use'

export const HeaderContext = React.createContext()

const HeaderProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  //   console.log(isVisible)

  return (
    <HeaderContext.Provider
      value={{ isVisible: isVisible, setIsVisible: setIsVisible }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderContext

export { HeaderProvider }
