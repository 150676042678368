import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { PortalProvider } from "react-portal-hook";
import { HeaderProvider } from './src/components/Header/HeaderContext'

export default ({ element, props  }) => {
    return (
        <HeaderProvider>
            <GlobalStyles/>
            <PortalProvider>
                {element}
            </PortalProvider>
        </HeaderProvider>
    )
}